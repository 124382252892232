import React, { useEffect, useState } from "react";
import style from './index.module.less'
import SwiperPic from "../SwiperPic";
import {
    m8,
    m2,
    m3,
    m4,
    m5,
    m6,
    m7
} from '@/images/experience/index'
import { MirrorView, EasySetup, EnjoyAr, Drm } from '@/components/SofaPart/index'
import { CompatibilityDevice } from '@/components/index'
import getCountry from '@/utils/getCountry.js'
import { useIntl } from "react-intl"

import {
    ipadIcon,
    appleIcon,
    androidPhoneIcon,
    steamIcon,
    switchIcon,
    ps5Icon,
    xboxIcon,
    windowIcon,
    macIcon
} from '@/images/index'



const picList = [
    m8,
    m2,
    m4,
    m5,
    m6,
    m7
]
const SimpleM = () => {
    const country = getCountry()
    const intl = useIntl()

    const connectList1 = [
        {
            img: androidPhoneIcon,
            name: intl.formatMessage({ id: "compatibility_android", defaultMessage: "Android Devices" })
        },
        {
            img: ipadIcon,
            name: "iPad"
        },
        {
            img: windowIcon,
            name: "Windows"
        },
        {
            img: macIcon,
            name: "MacBook"
        },
        {
            img: steamIcon,
            name: "Steam Deck"
        }
    ]
    const connectList2 = [
        {
            img: appleIcon,
            name: "iPhone/iPad"
        },
        {
            img: switchIcon,
            name: "Nintendo Switch"
        },
        {
            img: ps5Icon,
            name: "PlayStation 5"
        },
        {
            img: xboxIcon,
            name: "Xbox"
        }
    ]
    useEffect(() => {

        let region = ''
        if (country == 'base' || country == 'us') {
            region = 'US'
        } else if (country == 'th') {
            region = 'US'
        } else {
            region = country.toUpperCase()
        }
    }, [])
    return (
        <div className={style.box}>
            <div className={style.fittingBox}>
                <h2 className={style.title}>
                    {intl.formatMessage({ id: "experience_air_text1", defaultMessage: " Fitting A 130\" Monitor in Your Pocket" })}
                </h2>
                <h4 className={style.fittingBoxText}>
                    {intl.formatMessage({ id: "experience_air_text2", defaultMessage: " Enjoy the beautiful 130\" screen that amplifies your mobile, PC and gaming experience. No more neck pains. Watch a movie or play a game while lying in bed or on a pool chair. The fun is yours to choose." })}

                </h4>
            </div>
            <MirrorView />
            <EasySetup />
            <EnjoyAr />
            <Drm />
            <SwiperPic picList={picList} />
            <div className={style.compatibilityTitle}>{intl.formatMessage({ id: "support_how_connect", defaultMessage: "Compatibility" })}</div>
            <div style={{ padding: "0 30px" }}>
                <div className={style.compatibilityTypeTitle}>{intl.formatMessage({ id: "compatibility_text1", defaultMessage: "Directly connect to any device with USB-C video output" })}</div>
                <CompatibilityDevice type='else' elseList={connectList1} />
                <div className={style.compatibilityTypeTitle2}>{intl.formatMessage({ id: "compatibility_text2", defaultMessage: "Connect via XREAL Adapter to Lightning and HDMI ports" })}</div>
                <CompatibilityDevice type='else' elseList={connectList2} />
            </div>

        </div>
    )
}
export default SimpleM