import React, { useEffect, useState } from "react";
import style from './index.module.less'
import { DownloadBox } from "../index";
import { SwiperTrack, ShiXinHeiBtn_updown } from '@/components/index'
import { SwiperSlide } from "swiper/react";
import { youtubeIcon, teleportIcon, arspaceGlass, arspaceMedia1, arspaceMedia2, } from '@/images/experience/index'
import useNavigate from '@/hooks/useNavigate'
import { useIntl } from "react-intl";
import ScrollDeskTop from './Andr-Scroll/index'
import { getAppShow } from '@/services/request'
import { getCompatibilityCode } from '@/utils/regions.js'

const ARSpace = () => {
    const intl = useIntl()
    const [imgList, setImgList] = useState([''])
    const compCode = getCompatibilityCode()

    useEffect(() => {
        getAppShow()
            .then(d => {
                let arr = []
                d?.data?.data.map(item => {

                    arr.push(item.poster)
                })

                setImgList(arr)
            })

    }, [])
    const mediaList = [
        {
            media: arspaceMedia1,
            icon: youtubeIcon,
            title: "YouTube for AR",
            text: intl.formatMessage({ id: "experience_ar_text1", defaultMessage: "Watch YouTube videos in a waterfall interface design. Enlarge the video screen to immerse yourself in a limitless video experience." })
        },
        {
            media: arspaceMedia2,
            icon: teleportIcon,
            title: "Teleport",
            text: intl.formatMessage({ id: "experience_ar_text2", defaultMessage: "\"Place\" often plays an important role in creating a natural connection for strangers. Come into 3D scans of real world places and see for yourself. In the future, don't just take pictures. Record the location too." })
        }
    ]

    return (
        <div className={style.ar}>
            <div className={style.arTop}>
                {/* <div className={style.arTopTitle}>
                    Nebula Space - Your AR Portal
                </div> */}
                <div className={style.arTopDesc}>
                    {intl.formatMessage({ id: "arlab_text_2", defaultMessage: "  AR Space is your personal portal to a suite of experimental AR content, some developed by XREAL and some by 3rd party developers. AR Space is currently only available on compatible Android devices and requires the installation of Nebula for Android." })}

                </div>
            </div>
            <ScrollDeskTop />
            <img src={arspaceGlass} className={style.scrollGlass} />

            <div className={style.media}>
                {mediaList.map((item, index) => (
                    <div className={style.mediaItem} key={`arSpace${item.title}`} style={{ flexDirection: index == 0 ? ' row-reverse' : "row" }}>
                        <div className={style.mediaItemImg}>
                            <div className={style.mediaItemImgContent} style={{ backgroundImage: `url(${item.media})` }}>

                            </div>
                        </div>
                        <div className={style.mediaItemContent}>
                            <img src={item.icon} />
                            <div className={style.mediaItemContentTitle} style={{ color: index == 0 ? '#EB1D24' : '#812FF5' }}>{item.title}</div>
                            <div className={style.mediaItemContentText} dangerouslySetInnerHTML={{
                                __html: item.text,
                            }}></div>
                        </div>
                    </div>
                ))}

            </div>
            <div className={style.arlab}>
                <div className={style.arlabTitle}>AR Lab</div>
                <div className={style.arlabDesc}>{intl.formatMessage({ id: "arLab_intro", defaultMessage: "We have received quite some interesting AR apps from developers and put them all here for your pleasure. Hats off to all the innovative pioneers." })}</div>
                <SwiperTrack reverseDirection={true} options={{ speed: 7000 }}>
                    {imgList.map((item, index) => (
                        <SwiperSlide key={`arlabImgSwipet${index}`}>
                            <div className={style.swiperItem} key={`arlabImg${index}`} style={{ backgroundImage: `url(${item})` }}></div>
                        </SwiperSlide>
                    ))}


                </SwiperTrack>
                <SwiperTrack options={{ speed: 7000 }}>
                    {imgList.map((item, index) => (
                        <SwiperSlide key={`arlabImgSwiper${index}`}>
                            <div className={style.swiperItem} key={`arlabImg${index}`} style={{ backgroundImage: `url(${item})` }}></div>
                        </SwiperSlide>
                    ))}


                </SwiperTrack>
                <ShiXinHeiBtn_updown styles={{ margin: "80px auto" }} onClick={() => useNavigate('/arlab')}>
                    <div className={style.arlabBtn} >
                        {intl.formatMessage({ id: "nebula_goArlab", defaultMessage: "Go To AR Lab" })}</div>
                </ShiXinHeiBtn_updown>
            </div>
            <DownloadBox type={'mobile'} />
        </div>
    )
}
export default ARSpace